<template>
  <div class="home">
    
    <div class="container-fluid">
      <principal-navbar class="row" />
    </div>

    <!-- ABOUT US -->
    <principal-aboutus type="true" />

  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo() {
    return { 
      title: "Aceros Prolider SA de CV - Nosotros",
      meta: [
        { name: 'description', content:  'Somos una empresa orgullosamente Mexicana, con un equipo de colaboradores altamente capacitado, comprometido y con un gran gusto por el servicio a nuestros clientes.'} ,
        { property: 'og:title', content: "Aceros Prolider SA de CV - Nosotros" },
        { property: 'og:site_name', content: 'Aceros Prolider SA de CV - Nosotros' },
        { property: 'og:type', content: 'website'},
      ]
    }
  },
  components: {
    PrincipalNavbar: () => import('@/components/PrincipalNavbar.vue'),

    PrincipalAboutus: () => import('@/components/PrincipalAboutus.vue'),
  }
}
</script>